(function($) {
	'use strict';

	function video() {

	  // meaning this is not touch device
	  var flag = false;

	  // get video/s
	  var $mylanVideo = $('.mylan_video');

	  // call this function only if there are videos on the page
	  if ($mylanVideo.length) {

	    // get owl object
	    var owl = $(".view-hp-videos .owl-carousel").data('owlCarousel');

	    // check if there are more videos meaning owl plugin has initialized
	    if (owl) {

	      // when video has ended
	      $mylanVideo.on('ended', function () {

	        // check if current video is last one
	        if (owl.currentItem + 1 === owl.itemsAmount) {

	          // go to fist video
	          owl.goTo(0);
	        }

	        // and go to next video if not
	        else {
	          owl.next();
	        }
	      });

	      owl.reinit({

					// after slider has been initialized
	        afterInit: function (e) {
	          var pagination = owl.owlControls.find('.owl-page');
						// create style in head as a css container
						var inlineStyle = $('<style id="inline-css-for-owl" />').appendTo(document.head);
						// defining variables
						var css = '';
						var videoPath;
						// each every owl item
	          owl.$owlItems.each(function (i) {
							// take its video poster
	            videoPath = $(this).find('video').attr('poster');
							// put css in a variable
							css += '.view-id-hp_videos .owl-page:nth-child('+Number(i+1)+') {background-image: url(' + videoPath + ')}\n';
	          });
						// put all css inside of <style> tag in <head>
						inlineStyle.text(css);
	        },
					
	        // after we go to next/prev video
	        afterMove: function (e) {

	          // if 
	          if (flag == false) {

	            $mylanVideo.each(function (e) {
	              this.pause();
	            });

	            // play current video from start
	            $mylanVideo[owl.currentItem].currentTime = 0;
	            $mylanVideo[owl.currentItem].play();
	          }
	        }
	      });
	    }

	    // if only one video
	    else {

	      // set it to loop
	      $mylanVideo[0].loop = true;
	    }

	    // initial play of 1st video
	    $mylanVideo[0].play();

	    // check if video is playing (usually used for mobile devices)
	    if ($mylanVideo[0].paused) {

	      // this is touch device and video autoplay is disabled by default
	      flag = true;

	      // remove video and set some class for easier theming
	      // $('.views-field-field-hp-video-file').remove();
	      $('.view-hp-videos .group-wrapper').addClass('no-video');

	      // play video on click
	      // $mylanVideo.eq(0).on('click', function() {

	      // 	this.play();
	      // });
	    }
	  }
	}


	// Make blocks clickable
	function makeBlocksClickable(elem, linkA) {

		$(elem).each(function() {

			var link = $(this).find(linkA).attr("href");

			if (link) {

				$(this)

				.on('mouseover', function(e) {
					$(this).css('cursor', 'pointer');
				})

				.on('click', function(e) {
					window.location = link;
					return false;
				});
			}
		});
	}

	Drupal.behaviors.titleTakeFlue = {
	  attach: function (context) {
	  	// second title is not translated by drupal and single page app
			// take title from item 3 ( translated ) attached to item 4
	  	$('body', context).not('.i18n-en').once(function() {
	  		var singlePage = $(this).find('.single-page-wrapper');
				singlePage.eq(3).find('.single-page-title').text( singlePage.eq(2).find('.single-page-title').text() );
			});
	  }
	};

	Drupal.behaviors.didYouKnowSlick = {
		attach: function(context, settings) {

			$('.view-did-you-know', context).once(function() {

				// initialize slick object
				var slick = $('.view-did-you-know .view-content').slick({
					dots: true,
					autoplay: true,
					prevArrow: '<div class="slick-arrow slick-left"><i class="fa fa-angle-left"></i></div>',
					nextArrow: '<div class="slick-arrow slick-right"><i class="fa fa-angle-right"></i></div>'
				});

				// create vars
				var $viewHeader,
					viewHeaderText;

				// assign values to them
				$viewHeader = slick.siblings('.view-header');
				viewHeaderText = $viewHeader.text().trim().split(" ");

				// do stuff after slick change slide
				slick.on('afterChange', function(event, slick, currentSlide, nextSlide) {

					// assing new text value to view header
					$viewHeader.text(currentSlide + 1 + " " + viewHeaderText[1] + " " + viewHeaderText[2]);

				});
			});
		}
	};

	// Drupal.behaviors.PostcardsOwl = {
	// 	attach: function(context, settings) {
	// 		$('#all-postcards', context).once(function() {
	// 
	// 			$(this).owlCarousel({
	// 				items: 3,
	// 				singleItem: false,
	// 				mouseDrag: true,
	// 				touchDrag: true,
	// 				navigation: true,
	// 				navigationText: ['<i class="icon-arrow"></i>', '<i class="icon-arrow"></i>'],
	// 				pagination: false,
	// 				lazyLoad: true,
	// 				autoplay: false,
	// 				slideSpeed: 1200,
	// 				afterInit: function() {
	// 					$('#edit-postcard-id').val($($(this)[0].$userItems[$(this)[0].currentItem]).find('.postcard-nid').text());
	// 					// $('<div class="postcards__header"></div>').insertBefore('#all-postcards');
	// 					// $('.postcards__header').html($(this)[0].currentItem + 1 + Drupal.t(' of ') + $(this)[0].itemsAmount);
	// 				},
	// 				afterMove: function() {
	// 					$('#edit-postcard-id').val($($(this)[0].$userItems[$(this)[0].currentItem]).find('.postcard-nid').text());
	// 					// $('.postcards__header').html($(this)[0].currentItem + 1 + Drupal.t(' of ') + $(this)[0].itemsAmount);
	// 				},
	// 				afterAction: function(el) {
	// 					var arr = this.visibleItems;
	// 					var middle = arr[Math.round((arr.length - 1) / 2)];
	// 					this.$owlItems.removeClass('active--center')
	// 					this.$owlItems.eq(middle).addClass('active--center')
	// 				}
	// 			});
	// 		});
	// 	}
	// };
	
	Drupal.behaviors.PostcardsCarousel = {
		attach: function(context, settings) {
						
			/* ROTATE GALLERY */
	
			$('#all-postcards',context).once('test', function() {
				var slick, currentSlide, iid, field,
						$this = $(this);

				setTimeout(function() {

					slick = $this.slick({
						slidesToShow: 1,
						slidesToScroll: 1,
						arrows: true,
						fade: false,
						asNavFor: '#all-postcards2'
					});

					currentSlide = slick.slick('slickCurrentSlide');
					iid = slick.find('.slick-current').find('.postcard-nid').text();
					field = $('#edit-postcard-id');

					field.val(iid);

					slick.on('afterChange', function(event, slick, currentSlide, nextSlide ) {
						iid = $(this).find('.slick-current').find('.postcard-nid').text();
						field.val(iid);
					});

				}, 100);

			});

			$('#all-postcards2',context).once('test2', function() {
				var $this = $(this);

				setTimeout(function() {
					$this.slick({
						slidesToShow: 3,
						slidesToScroll: 1,
						asNavFor: '#all-postcards',
						dots: false,
						centerMode: true,
						focusOnSelect: true,
						arrows: false,
						swipe: false,
						// responsive: [
						// 	{
						// 		breakpoint: 1400,
						// 		settings: {
						// 			slidesToShow: 2,
						// 			slidesToScroll: 1
						// 		}
						// 	}
						// ]
					});
				}, 101 );

			});

			// $('body', context).once(function() {
				// $('.multi-postcards').wrapAll('<div class="postcards-slider-wrapper">');
			// });
	
		}
	};
  
	Drupal.behaviors.funnyReminderBlockTitleWrap = {
		attach: function(context, settings) {



			$('#block-funny-reminder-funny-reminder').once(function() {

				$('#block-funny-reminder-funny-reminder h2').wrapInner('<span></span>');
			});

		}
	};

	// Drupal.behaviors.sidebarRight = {
	// 	attach: function(context, settings) {
  //
	// 		$('#takefluseriously', context).once(function() {
	// 			var documentHeight, wrapperOffset, wrapperHeight, bottomLimit;
  //
	// 			function getVars(el) {
	// 				documentHeight = $(document).height();
	// 				wrapperOffset = el.offset();
	// 				wrapperHeight = el.outerHeight();
	// 				bottomLimit = documentHeight - wrapperOffset.top - wrapperHeight;
	// 			}
  //
	// 			//$('#takefluseriously')
	// 			getVars( $(this) );
  //
	// 		});
	// 	}
	// };

	var count = 0,
			linkWrapper;

	Drupal.behaviors.removingImgAttributes = {
		attach: function(context) {
			$('.field-name-field-facts-icons', context).once('img-attributes-removal', function() {
				$(this).find('img').each(function(){
					$(this).removeAttr('width').removeAttr('height');
				});
			});
		}
	};
	
	Drupal.behaviors.languageSelector = {
		attach: function(context, settings) {
			
			$('#block-locale-language', context).once('is-clicked', function() {
				var animSpeed = 100,
						state = false,
						count = 0,
						$this = $(this);

				// hide items on load
				$this.addClass('closed');

				$('.language-switcher-locale-url li').each(function() {
					var li 	= $(this);
					var a 	= $(this).find('a');
					if(a.hasClass('active')) {
						li.parent().prepend(li);
						li.addClass('active-parent').siblings().toggleClass('gone');
					}
				});

				var langLink = $(this).find('.language-switcher-locale-url li').not('.active-parent'),
						langLinkLen = langLink.length;

				// console.log( langLinkLen );
				
				
				$(this).find('.language-switcher-locale-url a.active').on('click', function(e) {
					e.preventDefault();
					if( $(this).hasClass('toggle-open') ) {
						close( langLinkLen - 1 );
						$(this).removeClass('toggle-open');
					} else {
						open(0);
						$(this).addClass('toggle-open');
					}
				});

				$('body').on('click', function(e) {
					if( $(e.target).is( $('#block-locale-language') ) || $(e.target).closest('#block-locale-language').length == 0 ) {
						if( state == true ) {
							close( langLinkLen - 1 );
							$this.find('.language-switcher-locale-url a.active').removeClass('toggle-open');
						}
					}
				});

				function open(linkIndex) {
					state = true;
					var next = linkIndex  + 1;

					count++;
					if( count == 1 ) {
						$this.removeClass('closed');
					} else if ( count == langLinkLen ) {
						count = 0;
					}

					langLink.eq(linkIndex).toggleClass('gone');

					if( next <= langLinkLen - 1 ) {
						setTimeout(function() {
							open(next);
						}, animSpeed);
					}
				}

				function close(linkIndex) {
					var next = linkIndex  - 1;
					state = false;

					count++;
					if( count == langLinkLen ) {
						$this.addClass('closed');
						count = 0;
					}

					langLink.eq(linkIndex).toggleClass('gone');

					if( next >= 0 ) {
						setTimeout(function() {
							close(next);
						}, animSpeed);
					}
				}

			});
		}
	};

	Drupal.behaviors.factsIcon = {
		attach: function(context) {
			//node-facts

			//@TODO here we are
			var x = 0;
			$('.node-facts .field-name-field-facts-icons', context).once(function() {

				var wrapper = $(this).find('.field-item img').wrap('<div class="img-wrapper" />').parent(),
						$this = $(this);

				setTimeout(function(){
					wrapper.matchHeight();

					if( x == 1 ) {
						$('.node-facts .field-name-field-facts-icons').matchHeight();
					} else {
						x++;
					}

				});
			});

		}
	};
	
	Drupal.behaviors.storiesOwlCarousel = {
		attach: function(context, settings) {
			$('.view-stories.view-display-id-page .view-content', context).once(function() {

				$(this).owlCarousel({
					items: 3,
					itemsDesktop: false,
					itemsDesktopSmall: false,
					itemsTablet: [752, 2],
					itemsTabletSmall: [534, 1],
					itemsMobile: false,
					singleItem: false,
					mouseDrag: true,
					touchDrag: true,
					navigation: true,
					navigationText: ['<i class="icon-arrow-left"></i>', '<i class="icon-arrow-right"></i>'],
					pagination: false,
					lazyLoad: true,
					autoplay: false,
					slideSpeed: 1200
				});				
			});
		}
	};

	Drupal.behaviors.viewStories = {
	  attach: function (context, settings) {

			$('.view-stories', context).once('view-stories-mh', function() {
				
				$(this).find('.views-field-title').matchHeight();
			});
	  }
	};

	
	Drupal.behaviors.eqHeights = {
	  attach: function (context, settings) {
			
			$('#single-page-overall-wrapper', context).once('group-wrapper-mh', function() {

				$(this).find('.node-facts.node-teaser .group-wrapper').matchHeight();
			});
	  }
	};
	
	Drupal.behaviors.imgFloat = {
		attach: function (context, settings) {
			var floatDirection;
			$('.field-name-body img').each(function () {
				floatDirection = $(this).css('float');
				switch (floatDirection) {
				case 'left':
					$(this).addClass('float--left');
					break;
				case 'right':
					$(this).addClass('float--right');
					break;
				}
			});
		}
	};
	
	Drupal.behaviors.learnWhyLink = {
		attach: function (context, settings) {
			$('.views-field-field-hp-video-anchor-link', context).once(function() {
				var nextSectionId = $(this).closest('.single-page-wrapper').next().attr('id');
				$(this).find('a').prop('href', '#' + nextSectionId);
			});
		}
	};
	
	// Drupal.behaviors.fatherOfAbsoluteChild = {
	// 	attach: function(context, settings) {
	// 		$('#all-postcards', context).once(function() {
	// 			
	// 			var $this = $(this);
	// 			var defaultHeight, newHeight;
	// 			
	// 			setTimeout(function(){
	// 				defaultHeight = $this.children('.now').height();
	// 				$this.height(defaultHeight);
	// 			},500);
	// 			
	// 			$(window).resize(function(){
	// 				
	// 				newHeight = $this.children('.now').height();
	// 					
	// 					if(defaultHeight != newHeight) {
	// 						$this.height(newHeight);						
	// 					}
	// 			});
	// 		});
	// 	}
	// };

	// navigation for mobile devices
	Drupal.behaviors.mobileMenu = {
	  attach: function (context) {

			$("#block-system-main-menu", context).once('mobile-menu', function() {

				var tpl = "<button class='c-hamburger c-hamburger--htx'><span>toggle menu</span></button>",
					button, nav;

				nav = $(this).find('.content');
				nav.addClass('nav-content');
				button = $(tpl).appendTo($(this));

				nav.find('a').on('click', function(e) {
					if( $(window).width() <= 1025 ) {
						button.removeClass("is-active");
						nav.stop().slideUp();
						$('body').removeAttr('style');
					}
				});

				button.on('click', function(e) {
					e.preventDefault();

					if( this.classList.contains("is-active") === true ) {
						this.classList.remove("is-active");
						nav.stop().slideUp();
						$('body').removeAttr('style');
					} else {
						this.classList.add("is-active");
						nav.stop().slideDown();
						$('body').css({'overflow':'hidden'});
					}

				});
			});
	  }
	};

	Drupal.behaviors.fixedMenu = {
	  attach: function (context, settings) {
//@TODO menu
			$($('.page-single-page-site, .node-type-story , .node-type-about').find('#header'), context ).once('fixMenu', function() {

				var menuVisible 	= false,
						menuFixed 	  = false,
						clFixed			  = 'affixed',
						clMaximized	  = 'is-maximized',
						clTransition  = 'transitioning',
						lastScrollTop = 0,
						$header 			= $(this),
						direction			= 'down';

				$(window).scroll(function(e) {
					var st = $(this).scrollTop();

					if (st > lastScrollTop) {
						// downscroll code
						if( menuVisible == true ) {
							$header.removeClass(clMaximized);
							menuVisible = false;
							$('body').trigger('click');
						}
						direction = 'down';
					} else {
						// upscroll code
						if( menuVisible == false ) {
							$header.addClass(clMaximized);
							menuVisible = true;
						}
						direction = 'up';
					}
					lastScrollTop = st;

					// show hide
					if( st > 160 && menuFixed == false ) {
						$header.addClass( clFixed );
						setTimeout(function(){ $header.addClass(clTransition) });
						menuFixed = true;
					} else if( st < 160 && menuFixed == true && direction != 'up' ) {
						$header.removeClass(clTransition).removeClass( clFixed );
						menuFixed = false;
					}

				});

			});
	  }
	};

	$(document).ready(function() {

		video();

	});

})(jQuery);